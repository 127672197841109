<template>
    <modal-dialog
        id="students-list-editor"
        :show.sync="shown"
        :dismissable="true"
        :droppable="true"
        @upload="upload_file"
        class="modal-lg"
    >
        <template v-slot:title>
            <div v-if="bulk_add_mode" class="h5">
                {{ $root.translate("Add Multiple Students") }}
            </div>
            <div v-else class="h5">
                {{ $root.translate("A new year, a fresh cohort!") }}
                <help-button class="popover-right">
                    <h6>{{ $root.translate("Build your new group(s)") }}</h6>
                    <p>{{ $root.translate("Select the students that will be part of your group(s) this year, from the list of students in your school moving up to your group's grade.") }}</p>
                    <p>{{ $root.translate("If you have new students joining your school, they will not appear in the initial list. You can add them individually or import a list of students.") }}</p>
                    <p>{{ $root.translate("The file you use for import can be created in Excel or Google Sheets, one column with the student's first name and the second column with their last name. Save the file as 'CSV' to be able to import it.") }}</p>
                    <p>{{ $root.translate("For imported students, a username, password and avatar will be generated when you save your group's students list.") }}</p>
                </help-button>
            </div>

            <div
                v-if="!fetching && (groups.length <= 1)"
                class="h6"
            >
                {{ group.name }}
            </div>
            <form-dropdown
                v-else-if="!fetching"
                id="group_id"
                name="group_id"
                v-model="group_id"
                :options="groups"
                :required="true"
            />
        </template>

        <div
            v-if="fetching"
            class="py-8"
        >
            <loader size="sm" />
        </div>
        <div v-else>
            <div class="columns">
                <div v-if="!bulk_add_mode" class="column col-6 col-sm-12">
                    <h6 class="mb-4">{{ $root.translate("Select Existing Students") }}</h6>
                    <data-table
                        id="students-list-editor"
                        :columns="visible_columns"
                        :rows="filtered_rows"
                        :search="state.search"
                        :total="state.total"
                        :found="state.found"
                        :current-page="state.page"
                        :total-pages="nb_pages"
                        :can-add="false"
                        :selection="selected_row_ids"
                        :show-head="false"
                        :show-totals="false"
                        @select="select_row"
                        @clear-selection="clear_selection"
                        @filter-by-selection="filter_by_selection"
                        @click="select_row($event.id, selected_row_ids.indexOf($event.id) < 0)"
                        @sort="sort_by"
                        @search="search"
                        @page="goto_page"
                    >
                        <template
                            v-slot:summary="{ row }"
                        >
                            <img
                                v-if="!!row.image_url"
                                :src="row.image_url"
                                class="mr-2"
                            />
                            {{ row.username }}
                            <div class="text-gray text-small">{{ row.teachers }}</div>
                        </template>
                        <template
                            v-slot:tbody="{ row, field }"
                        >
                            <div
                                v-if="field === 'username'"
                            >
                                <img
                                    v-if="!!row.image_url"
                                    :src="row.image_url"
                                    class="mr-2"
                                />
                                {{ row.username }}
                                <span class="text-gray ml-2">({{ row.teachers }})</span>
                            </div>
                            <span v-else>{{ row[field] }}</span>
                        </template>
                    </data-table>
                </div>
                <div
                    :class="{ 'col-12': bulk_add_mode, 'col-6': !bulk_add_mode }"
                    class="column col-sm-12"
                >
                    <h6>
                        <span v-if="bulk_add_mode">{{ $root.translate("New students") }}</span>
                        <span v-else>{{ $root.translate("And/or add new students") }}</span>
                        <default-button
                            flavor="link"
                            size="sm"
                            :title="$root.translate('Import New Students')"
                            @click.prevent="$refs.file.click()"
                        >
                            <open-icon glyph="file-upload" />
                        </default-button>
                        <base-link
                            href="/sample-students-list"
                            :title="$root.translate('Sample Students List')"
                            class="btn btn-link btn-sm float-right"
                        >
                            <open-icon glyph="file-download" /> {{ $root.translate("Sample Students List") }}
                        </base-link>
                    </h6>
                    <div class="mt-3 mb-4">
                        <small>{{ $root.translate("Usernames, passwords and avatars will be generated on save.") }}</small>
                    </div>
                    <table class="table nibnut-data-table">
                        <tbody>
                            <tr
                                v-for="(student, index) in new_students"
                                :key="student.id"
                                :id="`new-student-${student.id}`"
                            >
                                <td>
                                    <form-input
                                        :id="`first_name-${student.id}`"
                                        type="text"
                                        name="first_name"
                                        v-model="student.first_name"
                                        :required="false"
                                        :placeholder="$root.translate('First Name')"
                                    />
                                </td>
                                <td>
                                    <form-input
                                        :id="`last_name-${student.id}`"
                                        type="text"
                                        name="last_name"
                                        v-model="student.last_name"
                                        :required="false"
                                        :placeholder="$root.translate('Last Name')"
                                    />
                                </td>
                                <td>
                                    <default-button
                                        flavor="link"
                                        color="error"
                                        @click.prevent="delete_new_student(index)"
                                    >
                                        <open-icon glyph="times" />
                                    </default-button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="3">
                                    <default-button
                                        flavor="link"
                                        :block="true"
                                        size="sm"
                                        @click.prevent="add_new_student"
                                    >
                                        <open-icon glyph="plus" /> {{ $root.translate("Add New Student") }}
                                    </default-button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                    <input
                        v-if="file_field_ready"
                        name="student-import-file-picker"
                        type="file"
                        ref="file"
                        accept="text/csv,.csv"
                        @change="upload_picked_file"
                        class="nibnut-proxied-control"
                    />
                </div>
            </div>
        </div>

        <template v-slot:footer>
            <div v-if="!fetching" class="columns">
                <div
                    v-if="!!managing_students_list && !bulk_add_mode"
                    class="column col-6 col-sm-12 text-left"
                >
                    <default-button
                        flavor="link"
                        :waiting="waiting === 'dont-ask'"
                        :disabled="!!waiting"
                        @click.prevent="act('dont-ask')"
                    >
                        {{ $root.translate("Don't ask me again") }}
                    </default-button>
                </div>
                <div
                    :class="{ 'col-6': !!managing_students_list && !bulk_add_mode, 'col-12': !managing_students_list || bulk_add_mode }"
                    class="column col-sm-12"
                >
                    <default-button
                        v-if="!!managing_students_list && !bulk_add_mode"
                        :waiting="waiting === 'later'"
                        :disabled="!!waiting"
                        @click.prevent="act('later')"
                    >
                        {{ $root.translate("Ask me later") }}
                    </default-button>
                    <default-button
                        v-else
                        :disabled="!!waiting"
                        @click.prevent="shown = false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        :waiting="waiting === 'save'"
                        :disabled="!!waiting || (!selected_row_ids.length && !new_students.length)"
                        class="ml-2"
                        @click.prevent="act('save')"
                    >
                        {{ $root.translate("Save") }}
                    </default-button>
                </div>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import orderBy from "lodash/orderBy"
import Papa from "papaparse"

import is_data_table_source from "@/nibnut/mixins/IsDataTableSource"
import handles_uploads from "@/nibnut/mixins/HandlesUploads"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import HelpButton from "@/nibnut/components/Buttons/HelpButton"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"
import Loader from "@/custom/components/Loader"

const temp_uuid = function () {
    return (Math.random().toString(36).substr(2, 9))
}

export default {
    name: "StudentsArchiver",
    components: {
        ModalDialog,
        HelpButton,
        FormDropdown,
        FormInput,
        DefaultButton,
        BaseLink,
        OpenIcon,
        Loader
    },
    mixins: [is_data_table_source, handles_uploads, addl_profile_utilities],
    mounted () {
        this.load()
    },
    watch: {
        managing_students_list: "load"
    },
    methods: {
        load () {
            if(this.is_at_least_teacher && this.profile.is_subscribed && (this.managing_students_list !== null)) {
                this.fetching = true
                const query = {
                    relation_ids: ["group_user"],
                    fields: ["fieldset::manager", "ns::group_user;fieldset::manager"]
                }
                if(this.profile.groups_to_manage && !!this.profile.groups_to_manage.length) query.ids = this.profile.groups_to_manage
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "group",
                        query
                    }
                ).then(() => {
                    const groups = this.groups
                    if(!groups || !groups.length) this.shown = false
                    else this.set_group_id(groups[0].id)
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.fetching = ""
                })
            }
        },
        set_group_id (group_id) {
            this.group_id = group_id
            this.selected_row_ids = []
            this.new_students = []
        },
        add_new_student () {
            const student = { id: temp_uuid(), first_name: "", last_name: "" }
            this.new_students.push(student)
            this.$nextTick(() => {
                document.getElementById(`new-student-${student.id}`).querySelector("input[name='first_name']").focus()
            })
        },
        delete_new_student (index) {
            if((index >= 0) && (index < this.new_students.length)) this.new_students.splice(index, 1)
        },
        upload_file (files) {
            if(!files || !files.length) return
            Papa.parse(files[0], {
                skipEmptyLines: true,
                complete: results => {
                    if(!results) this.$error(this.$root.translate("Invalid CSV file. Please try again."))
                    else if(!!results.errors && !!results.errors.length) {
                        results.errors.forEach(error => {
                            this.$error(`Row #${error.row}: ${error.message}`)
                        })
                    } else if(results.data) {
                        results.data.forEach(row => {
                            if(!!row[0] || !!row[1]) {
                                if((!row[0] && !!row[1]) || !row[0].match(/^first\s+name/i)) this.new_students.push({ id: temp_uuid(), first_name: row[0], last_name: row[1] })
                            }
                        })
                    }
                }
            })
        },
        upload_picked_file () {
            this.upload_file(this.$refs.file.files)
            this.file_field_ready = false
            this.$nextTick(() => {
                this.file_field_ready = true
            })
        },
        act (action_id) {
            this.waiting = action_id
            let ids = this.selected_row_ids
            if(action_id === "dont-ask") ids = 0
            else if(action_id === "later") ids = 1

            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "group",
                    id: this.group_id,
                    action: "manage-students",
                    data: {
                        ids,
                        new_students: this.new_students,
                        relation_ids: ["group_user", "user"],
                        fields: ["fieldset::manager", "ns::group_user;fieldset::manager", "ns::user;fieldset::profile"]
                    },
                    method: "post"
                }
            ).then(() => {
                if((action_id === "save") && !this.bulk_add_mode && !!this.raw_groups.length) this.set_group_id(this.groups[0].id)
                else this.shown = false
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.waiting = false
            })
        }
    },
    computed: {
        ...mapState(["managing_students_list"]),
        ...mapGetters(["entity_records"]),
        shown: {
            get () {
                return this.managing_students_list !== null
            },
            set (show) {
                this.$store.dispatch("SHOW_STUDENT_LIST_EDITOR", { show: show ? false : null })
            }
        },
        raw_groups () {
            if(!this.profile) return []
            if(this.bulk_add_mode) {
                const group_ids = this.profile_group_users.map(group_user => group_user.group_id)
                return this.entity_records("group").filter(group => {
                    return group_ids.indexOf(group.id) >= 0
                })
            }

            if(!this.profile.groups_to_manage) return []
            return this.entity_records("group").filter(group => {
                return this.profile.groups_to_manage.indexOf(group.id) >= 0
            })
        },
        groups () {
            return orderBy(this.raw_groups, "name", "asc")
        },
        group () {
            if(!this.group_id) return {}
            return this.raw_groups.find(group => group.id === this.group_id) || {}
        },
        rows () {
            if(!this.group_id) return []
            const student_role = this.constants("group_user_types", "ROLE_STUDENT").id
            const group = this.group
            return this.entity_records("group_user").filter(group_user => {
                return (group_user.role === student_role) && (group_user.group_grade === (group.grade - 1))
            })
        },
        bulk_add_mode () {
            return !this.profile.groups_to_manage || !this.profile.groups_to_manage.length
        }
    },
    data () {
        return {
            fetching: false,
            group_id: 0,
            waiting: "",
            file_field_ready: true,

            columns: {
                username: { label: this.translate("Student"), sort: "asc", type: "alpha" }
            },
            selected_row_ids: [],
            new_students: []
        }
    }
}
</script>

<style lang="scss">
#students-list-editor {
    table.table {
        img {
            max-height: 1em;
        }
    }
}
</style>
