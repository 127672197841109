<template>
    <div v-if="!!assignment" class="assignment-editor">
        <div>
            <game-tile
                :game="activity"
                size="sm"
                :hoverable="false"
            />
        </div>
        <div class="assignment-editor-setup">
            <h6>{{ activity.name }}</h6>
            <div v-if="!!activity.description" v-html="activity.description"></div>

            <div class="columns">
                <div class="column col-6 col-sm-12">
                    <form-dropdown
                        id="grade"
                        name="grade"
                        v-model="grade"
                        :options="grades"
                        :empty-value="-1"
                        :required="true"
                        :editable="editable"
                        @input="save_grade"
                    >
                        <template v-slot:label>{{ $root.translate("Grade") }}</template>
                    </form-dropdown>
                </div>
                <div class="column col-6 col-sm-12">
                    <form-dropdown
                        v-if="!!activity.with_text"
                        id="with_text"
                        name="with_text"
                        :value="assignment.with_text"
                        :options="audio_options"
                        :required="true"
                        :editable="editable"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Mode") }}</template>
                    </form-dropdown>
                </div>
            </div>
            <div v-if="editable && !!assignment.id" class="text-center mt-8">
                <default-button
                    flavor="link"
                    color="error"
                    size="sm"
                    @click.prevent="confirm_record_delete"
                >
                    {{ $root.translate("Delete Assignment") }}
                </default-button>
            </div>
        </div>
        <modal-dialog
            v-if="!!activity && !!activity.starting_levels"
            id="confirm-grade-change"
            :show.sync="confirming_grade_change"
        >
            <template v-slot:title>
                <span class="h5">{{ $root.translate("Change student grade") }}</span>
            </template>

            <p>{{ $root.translate("This grade change will change the student's in-game level from {old_level} to {new_level}. Are you sure you want to do proceed?", { old_level: assignment.current_level || "0", new_level: activity.starting_levels[grade] || "0" }) }}</p>

            <h6>{{ $root.translate("Starting Levels for each Grade") }}</h6>
            <table class="table">
                <thead>
                    <tr>
                        <th>{{ $root.translate("Grade") }}</th>
                        <th>{{ $root.translate("Starting Level") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(starting_level, grade) in activity.starting_levels"
                        :key="grade"
                    >
                        <td>{{ grade_by_id(grade).name }}</td>
                        <td>{{ starting_level }}</td>
                    </tr>
                </tbody>
            </table>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        class="mr-2"
                        @click.prevent="change_grade(false)"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        class="ml-2"
                        @click.prevent="change_grade(true)"
                    >
                        {{ $root.translate("Change Grade") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import handles_saving from "@/nibnut/mixins/HandlesSaving"
import confirms from "@/nibnut/mixins/Confirms"
import handles_grades from "@/custom/mixins/HandlesGrades"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import GameTile from "@/custom/components/GameTile"

export default {
    name: "Assignment",
    mixins: [handles_saving, confirms, handles_grades],
    components: {
        FormDropdown,
        DefaultButton,
        ModalDialog,
        GameTile
    },
    watch: {
        "assignment.asset_id": "asset_changed",
        "assignment.grade": "grade_changed"
    },
    mounted () {
        this.asset_changed()
        this.grade_changed()
    },
    methods: {
        asset_changed () {
            if(this.assignment && this.assignment.asset_id) {
                this.$store.dispatch(
                    "FETCH_RECORD",
                    {
                        entity: "asset",
                        id: this.assignment.asset_id,
                        query: { fields: ["fieldset::picker"] }
                    }
                ).catch(error => {
                    console.error(error.message)
                })
            }
        },
        grade_changed () {
            this.grade = this.assignment ? this.assignment.grade : -1
        },
        save (value, field) {
            if(this.assignment) {
                if(this.assignment[field] !== value) this.assignment[field] = value
                if(this.assignment.id) return this.save_field_for_record_id("assignment", this.assignment.id, this.assignment[field], field)
            }
            return Promise.resolve()
        },
        save_grade () {
            if(
                this.activity.starting_levels &&
                (this.activity.starting_levels[this.assignment.grade] !== undefined) &&
                (this.activity.starting_levels[this.grade] !== undefined) &&
                this.assignment.current_level &&
                ((this.assignment.current_level > this.activity.starting_levels[this.grade]) || (this.assignment.current_level > this.activity.starting_levels[this.assignment.grade]))
            ) {
                this.confirming_grade_change = true
                return
            }
            this.change_grade(true)
        },
        change_grade (change) {
            if(change) this.save(this.grade, "grade")
            else {
                setTimeout(() => {
                    this.grade = this.assignment.grade
                }, 500)
            }
            this.confirming_grade_change = false
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete Assignment"),
                    message: this.$root.translate("Do you really want to delete this assignment? Your student will start to their current level the next time this activity is assigned to them."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: "assignment",
                        id: this.assignment.id
                    }
                ).then(() => {
                    this.done_confirming()
                    this.$emit("deleted")
                }).catch(error => {
                    this.$error(error.message)
                })
            } else this.done_confirming()
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        activity () {
            if(!this.assignment || !this.assignment.asset_id) return {}
            return this.entity_record("asset", this.assignment.asset_id) || {}
        },
        grades () {
            const activity = this.activity
            if(activity) {
                return this.raw_grades.filter(grade => {
                    return !activity.grades || (activity.grades.indexOf(grade.id) >= 0)
                })
            }
            return this.available_grades
        },
        audio_options () {
            return [
                { id: true, name: this.$root.translate("Text and Audio") },
                { id: false, name: this.$root.translate("Audio only") }
            ]
        }
    },
    props: {
        assignment: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            grade: -1,
            confirming_grade_change: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.assignment-editor {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;

    & > div {
        margin: 0 $layout-spacing;

        &.assignment-editor-setup {
            flex: 1 1 auto;
        }
    }
}
</style>
