<template>
    <modal-dialog
        id="subscription-editor"
        :show.sync="shown"
        :dismissable="false"
        class="modal-lg"
    >
        <template v-slot:title>
            <span v-if="!editQuantity" class="h5">{{ $root.translate("Add/Update Credit Card") }}</span>
            <span v-else-if="!license.id" class="h5">{{ $root.translate("Subscribe") }}</span>
            <span v-else class="h5">{{ $root.translate("Change Number of Seats") }}</span>
        </template>
        <base-form
            v-if="!!license"
            :has-required-fields="true"
            @submit.prevent.stop="save"
        >
            <subscription-pricing-table
                v-if="editQuantity"
                :applied-coupon-code="coupon_code"
                :auto-applied="coupon_auto_applied"
                :min-seats="minimum_seats"
                :billing-country="user.country"
                :billing-province="user.state"
                :current-unit-price="current_unit_price"
                :payment-info="license.gateway_data"
                :soft-maximum="license.computed_soft_max_students"
                :hard-maximum="license.max_students"
                class="mb-4"
                @input="quantity = $event"
                @change="fee_change = $event"
            />

            <div class="columns">
                <div
                    v-if="!!editQuantity && !!owed_amount && !coupon_auto_applied"
                    class="column col-6 col-sm-12"
                >
                    <coupon-code-input
                        v-if="!!coupon || !!owed_amount"
                        :coupon="coupon"
                        class="mb-4"
                        :editable="!saving"
                        @input="coupon = $event"
                    />
                </div>
                <div
                    v-if="!!editQuantity && !!owed_amount"
                    :class="{ 'col-6': !coupon_auto_applied, 'col-12': !!coupon_auto_applied }"
                    class="column col-sm-12"
                >
                    <credit-card-input
                        id="gateway_token"
                        :owed-amount="owed_amount"
                        :editable="!saving"
                    />
                </div>
            </div>

            <div
                class="text-center mt-8"
            >
                <default-button
                    :disabled="!!saving"
                    class="mx-2"
                    @click.prevent="shown = false"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    v-if="owed_amount || !!fee_change"
                    type="submit"
                    color="primary"
                    :waiting="!!saving"
                    :disabled="!!saving"
                    class="mx-2"
                >
                    {{ $root.translate("Save") }}
                </default-button>
            </div>
        </base-form>
    </modal-dialog>
</template>

<script>
import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import handles_payments from "@/custom/mixins/HandlesPayments"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseForm from "@/nibnut/components/BaseForm"
import SubscriptionPricingTable from "@/custom/components/Inputs/SubscriptionPricingTable"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "SubscriptionDialog",
    mixins: [handles_payments, misc_utilities],
    components: {
        ModalDialog,
        BaseForm,
        SubscriptionPricingTable,
        DefaultButton
    },
    mounted () {
        this.setup_taxes()
    },
    watch: {
        "user.country": "setup_taxes",
        "user.province": "setup_taxes"
    },
    methods: {
        setup_taxes () {
            if(this.user) {
                this.country = this.user.country
                this.province = this.user.province
            }
            this.fee_change = 0
            this.coupon_code = this.setting("auto_apply_coupon_code")
        },
        save () {
            this.saving = true
            const card_last_four = (!!this.license && !!this.license.customer_info) ? this.license.customer_info.card_last_four : null
            this.tokenize_payment(this.user, card_last_four).then(({ gateway_token, billing_details }) => {
                const data = { relation_ids: ["user"], fields: ["ns::user;fieldset::default", "fieldset::billing"] }
                if(this.editQuantity) {
                    if(this.quantity) data.max_students = this.quantity
                    if(this.coupon) data.coupon_id = this.coupon.id
                }
                data.name = billing_details.name
                data.email = billing_details.email
                data.gateway_token = gateway_token
                if(this.license.id) {
                    this.$store.dispatch("RECORD_SAVE", {
                        entity: "license",
                        id: this.license.id,
                        data
                    }).then(() => {
                        this.shown = false
                    }).catch(error => {
                        this.$error(error)
                    }).then(() => {
                        this.saving = false
                    })
                } else {
                    this.$store.dispatch(
                        "CREATE_RECORD",
                        {
                            entity: "license",
                            data
                        }
                    ).then(() => {
                        this.shown = false
                    }).catch(error => {
                        this.$error(error.message)
                    }).then(() => {
                        this.saving = false
                    })
                }
            }).catch(error => {
                this.$error(error.message)
                this.saving = false
            })
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        minimum_seats () {
            return Math.max(this.user.minimum_seats, this.license.computed_nb_students)
        },
        current_unit_price () {
            return this.plan_price(this.license.plan_info, this.license.coupon_info, [])
        },
        owed_amount () {
            if(this.editQuantity) return (this.fee_change >= 0) ? this.fee_change : 0
            return this.plan_price(this.plan, this.coupon, this.taxes)
        },
        coupon_auto_applied () {
            const auto_apply_coupon_code = this.setting("auto_apply_coupon_code")
            return (!!auto_apply_coupon_code && (this.coupon_code === auto_apply_coupon_code))
        },
        proceed_without_payment_info () {
            if(this.editQuantity) return !this.owed_amount
            return false
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        license: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        editQuantity: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            saving: false,
            fee_change: 0
        }
    }
}
</script>
