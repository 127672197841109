<template>
    <modal-dialog
        id="assignment-editor"
        :show.sync="shown"
        :dismissable="true"
        :class="{ 'modal-lg': (current_panel_id === 'activities') }"
    >
        <template v-slot:title>
            <div class="h5 text-center">
                {{ translate("Assignment for {name}", { name: student.username }) }}
                <span v-if="!!assignment && !!assignment.current_level" class="text-small text-gray"> ({{ translate("Current level: {level}", { level: assignment.current_level }) }})</span>
            </div>
        </template>

        <transition
            name="slide"
            enter-active-class="animate__animated animate__faster animate__slideInUp"
            leave-active-class="animate__animated animate__faster animate__slideOutDown"
            mode="out-in"
        >
            <activities-grid
                v-if="current_panel_id === 'activities'"
                :student="student"
                :selection="assignment.asset_id"
                :subject-tag-id="subjectTagId"
                :only-types="[constants('asset_types', 'TYPE_GAME').id]"
                @input="select_activity"
            />
            <assignment
                v-if="current_panel_id === 'assignment'"
                :assignment="assignment"
                :editable="editable"
                @deleted="shown = false"
            />
        </transition>

        <template v-slot:footer>
            <div class="columns">
                <div v-if="editable" class="column text-left">
                    <default-button
                        v-if="(current_panel_id === 'assignment') && !!assignment && !!assignment.asset_id"
                        flavor="link"
                        @click.prevent="current_panel_id = 'activities'"
                    >
                        <open-icon glyph="arrow-left" class="mr-2" />
                        {{ translate("Select Activity") }}
                    </default-button>
                </div>
                <div class="column text-center">
                    <default-button
                        v-if="!assignment || !assignment.id"
                        flavor="link"
                        @click.prevent="shown = false"
                    >
                        {{ translate("Cancel") }}
                    </default-button>
                </div>
                <div class="column text-right">
                    <default-button
                        v-if="!!assignment && !!assignment.id && (current_panel_id !== 'assignment')"
                        :disabled="creating"
                        @click.prevent="current_panel_id = 'assignment'"
                    >
                        {{ translate("Done") }}
                    </default-button>
                    <default-button
                        v-else-if="editable && (current_panel_id === 'activities') && !!assignment && !!assignment.asset_id"
                        :disabled="creating"
                        @click.prevent="current_panel_id = 'assignment'"
                    >
                        {{ translate("Setup") }}
                        <open-icon glyph="arrow-right" class="ml-2" />
                    </default-button>
                    <default-button
                        v-else-if="editable && !assignment.id && (current_panel_id === 'assignment')"
                        color="primary"
                        :waiting="creating"
                        :disabled="creating"
                        @click.prevent="create"
                    >
                        {{ translate("Save") }}
                    </default-button>
                    <default-button
                        v-else
                        @click.prevent="shown = false"
                    >
                        {{ translate("Done") }}
                    </default-button>
                </div>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import { mapGetters } from "vuex"

import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import ActivitiesGrid from "@/custom/components/ActivitiesGrid"
import Assignment from "./Assignment"

export default {
    name: "AssignDialog",
    mixins: [misc_utilities],
    components: {
        ModalDialog,
        DefaultButton,
        OpenIcon,
        ActivitiesGrid,
        Assignment
    },
    mounted () {
        this.reset_panel()
    },
    watch: {
        show: "reset_panel",
        "assignment.asset_id": "reset_panel"
    },
    methods: {
        reset_panel () {
            this.$nextTick(() => {
                // this.current_panel_id = (!!this.assignment && !!this.assignment.asset_id) ? "assignment" : "activities"
                if((!!this.assignment && !!this.assignment.asset_id)) this.select_activity(this.assignment.asset_id)
                else this.current_panel_id = "activities"
            })
        },
        select_activity (asset_id) {
            const activity = this.entity_records("asset").find(activity => activity.id === asset_id)
            if(!!asset_id && !!this.student && !!this.student.id) {
                if(activity && activity.subject_tag_id) {
                    const subject_user = this.entity_records("subject_user").find(subject_user => {
                        return (subject_user.user_id === this.student.user_id) && (subject_user.subject_tag_id === activity.subject_tag_id)
                    })
                    if(this.assignment.grade < 0) {
                        this.assignment.grade = Math.min(subject_user ? subject_user.grade : 0, this.student.group_grade)
                        if(isNaN(this.assignment.grade)) this.assignment.grade = 0
                    }
                }
            }
            if(this.assignment.asset_id === asset_id) this.current_panel_id = "assignment"
            else this.assignment.asset_id = asset_id
        },
        create () {
            this.creating = true
            this.$store.dispatch(
                "CREATE_RECORD",
                {
                    entity: "assignment",
                    data: {
                        ...this.assignment,
                        fields: this.fields
                    }
                }
            ).then(record => {
                this.shown = false
                this.$emit("created")
            }).catch(this.receive_error).then(() => {
                this.creating = false
            })
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        editable () {
            return !!this.assignment && (!this.assignment.computed_last_seen_at || !this.assignment.completed_at)
        }
    },
    props: {
        assignment: {
            type: Object,
            required: true
        },
        student: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        subjectTagId: {
            type: Number,
            default: 0
        },
        show: {
            type: Boolean,
            default: false
        },
        fields: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            current_panel_id: "activities",
            creating: false
        }
    }
}
</script>

<style lang="scss">
#assignment-editor.modal-lg {
    .modal-container {
        max-width: none;
        max-height: 90vh;
    }
}
</style>
