<template>
    <div>
        <form-input
            :id="dismissable ? 'email' : 'first_focusable'"
            ref="first_field"
            v-model="user.email"
            name="email"
            type="email"
            :error="has_error('email')"
            :required="true"
            :maybe-autofilled="true"
            :editable="!creating"
            @input="save"
            @blur="save"
        >
            <template v-slot:label
            >{{ $root.translate("Email") }}</template>
        </form-input>
        <password-editor
            id="password"
            v-model="user.password"
            type="password"
            name="password"
            :required="true"
            :editable="!creating"
            :error="has_error('password')"
        >
            <template v-slot:label
            >{{ $root.translate("Password") }}</template>
        </password-editor>

        <div class="columns">
            <div class="column col-6 col-sm-12 mb-2">
                <form-input
                    id="first_name"
                    v-model="user.first_name"
                    name="first_name"
                    :required="false"
                    :editable="!creating"
                    :error="has_error('first_name')"
                >
                    <template v-slot:label
                    >{{ $root.translate("First Name") }}</template>
                </form-input>
            </div>
            <div class="column col-6 col-sm-12 mb-2">
                <form-input
                    id="last_name"
                    v-model="user.last_name"
                    name="last_name"
                    :required="false"
                    :editable="!creating"
                    :error="has_error('last_name')"
                >
                    <template v-slot:label
                    >{{ $root.translate("Last Name") }}</template>
                </form-input>
            </div>
        </div>

        <form-select
            v-if="!role_is_at_least_administrator(user.role)"
            id="institution_id"
            name="institution_id"
            v-model="user.institution_id"
            data-source="app"
            :required="false"
            :show-all="false"
            :empty-label="$root.translate('Not affiliated to an institution')"
            :editable="!creating"
            :error="has_error('institution_id')"
            @input="update_address"
        >
            <template v-slot:option="{ option }">
                    {{ option.name }}<span v-if="!!option.id"> ({{ option.city }}, {{ option.state }})</span>
            </template>
            <template v-slot:label>{{ $root.translate("School or Institution") }}</template>
            <template v-slot:hint>
                {{ $root.translate("If you are affiliated to a school or institution, start typing its name in the box above.") }}
                <base-link
                    href="#"
                    class="ml-2"
                    @click.prevent="draft_message"
                >
                    {{ $root.translate("Don't see your institution?") }}
                </base-link>
            </template>
        </form-select>

        <p class="text-gray mt-4">
            {{ $root.translate("By subscribing to L'Univers de Sam Amuse, I consent to receive \"La Boussole\" emails for the duration of my subscription. For members only, this email communication provides tutorials and how-to's to explore the platform. It's also the only way to be notified about new resources, technical difficulties and other important communications. You can unsubscribe at any time in \"My profile\" page and at the bottom of the email.") }}
        </p>

        <modal-dialog
            id="message-composer"
            :show.sync="drafting"
        >
            <template v-slot:title>
                <span class="h5">
                    {{ $root.translate("Missing School or Institution") }}
                </span>
            </template>

            <p>
                {{ $root.translate("Please provide us with as much information as possible in the missing institution, and we will do our best to add it to our database.") }}
            </p>
            <p>
                {{ $root.translate("In the meantime you can still signup without an affiliated institution; you will be able to affiliate yourself to it once it has been added to the platform.") }}
            </p>

            <form-input
                id="message_from"
                name="message_from"
                v-model="message_from"
                :required="true"
                :disabled="sending"
            >
                <template v-slot:label>{{ $root.translate("Email") }}</template>
            </form-input>

            <form-textbox
                id="message"
                name="message"
                v-model="message"
                size="lg"
                :required="true"
                :disabled="sending"
                :placeholder="$root.translate('Institution name, address, website, etc...')"
            >
                <template v-slot:label>{{ $root.translate("Message") }}</template>
            </form-textbox>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :disabled="sending"
                        class="mr-2"
                        @click.prevent="drafting = false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        :waiting="sending"
                        :disabled="sending"
                        class="ml-2"
                        @click.prevent="send_message"
                    >
                        {{ $root.translate("Send") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
    </div>
</template>

<script>
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import string_utilities from "@/nibnut/mixins/StringUtilities"

import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormSelect from "@/nibnut/components/Inputs/FormSelect"
import FormTextbox from "@/nibnut/components/Inputs/FormTextbox"
import PasswordEditor from "@/nibnut/components/Inputs/PasswordEditor"
import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import BaseLink from "@/nibnut/components/Links/BaseLink"

export default {
    name: "SignupInfo",
    mixins: [profile_utilities, string_utilities],
    components: {
        FormInput,
        FormSelect,
        FormTextbox,
        PasswordEditor,
        ModalDialog,
        DefaultButton,
        BaseLink
    },
    methods: {
        has_error (error_id) {
            if(error_id) return this.errors[error_id]
            return !!Object.keys(this.errors).length
        },
        save (value, field) {
            this.$emit("input", value, field)
        },
        update_address (institution_id, field, institution) {
            this.user.institution_id = parseInt(institution_id) || 0
            if(institution.name && (!this.selectedInstitution || (this.address_one_line(this.selectedInstitution) === this.address_one_line(this.user)))) {
                this.user.line1 = institution.line1
                this.user.line2 = institution.line2
                this.user.city = institution.city
                this.user.state = institution.state
                this.user.country = institution.country
                this.user.zip = institution.zip
            }
            this.$emit("institution", institution)
        },
        draft_message () {
            this.message_from = this.user.email
            this.message = ""
            this.drafting = true
        },
        send_message () {
            this.sending = true
            this.$store.dispatch("RECORDS_ACTION", {
                entity: "institution",
                action: "report-missing",
                data: { from: this.message_from, message: this.message },
                method: "post"
            }).then(() => {
                this.$success(this.$root.translate("Thank you: your message has been sent."))
                this.drafting = false
            }).catch(this.receive_error).then(() => {
                this.sending = false
            })
        }
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        selectedInstitution: {
            type: Object,
            default () {
                return null
            }
        },
        errors: {
            type: Object,
            default () {
                return {}
            }
        },
        creating: {
            default: false
        },
        dismissable: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            drafting: false,
            message_from: "",
            message: "",
            sending: false
        }
    }
}
</script>
