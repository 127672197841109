<template>
    <div>
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <panel-header
            @click="dismiss"
        />
        <div class="modal-body">
            <base-form
                :has-required-fields="false"
                @submit.prevent.stop="login"
            >
                <form-input
                    :id="dismissable ? 'access_code' : 'first_focusable'"
                    ref="access_code"
                    v-model="access_code"
                    name="access_code"
                    :error="has_error('access_code')"
                    :required="false"
                    autocomplete="none"
                    autocapitalize="none"
                    autocorrect="off"
                >
                    <template v-slot:label>
                        <span class="text-small">{{ $root.translate("What's your Group Access Code?") }}</span>
                    </template>
                </form-input>

                <div class="text-center mt-8">
                    <feedback-button
                        type="submit"
                        color="green"
                        :status="feedback_status"
                        :waiting="loading"
                        :single-use="false"
                        size="lg"
                    >
                        <template v-slot:message>
                            <div
                                :class="{ 'text-success': (feedback_status === true), 'text-error': (feedback_status === false) }"
                            >
                                {{ feedback_message }}
                            </div>
                        </template>
                        {{ $root.translate("Let me in!") }}
                    </feedback-button>
                </div>
            </base-form>
        </div>
        <div
            class="modal-footer"
        >
            <default-button
                id="last_focusable"
                flavor="link"
                size="sm"
                @click.prevent="remember_and_goto('login')"
            >
                {{ $root.translate("I am not a student / I don't have a code") }}
            </default-button>
        </div>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import is_panel from "@/nibnut/dialogs/Authentication/IsPanel"
import language_utilities from "@/custom/mixins/LanguageUtilities"

import BaseForm from "@/nibnut/components/BaseForm"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import FeedbackButton from "@/nibnut/components/Buttons/FeedbackButton"
import PanelHeader from "./PanelHeader"

export default {
    mixins: [is_panel, language_utilities],
    components: {
        BaseForm,
        FormInput,
        DefaultButton,
        FeedbackButton,
        PanelHeader
    },
    methods: {
        remember_and_goto (panel_id) {
            this.$cookie.set("login-mode", panel_id)
            this.goto(panel_id)
        },
        login () {
            setTimeout(() => {
                this.loading = true
                this.clear_all_errors()

                const data = {
                    access_code: this.access_code
                }

                this.$store.dispatch("GROUP_LOGIN", data).then(() => {
                    this.access_code = ""
                    this.langugage_reread()
                    if(this.$cookie.get("group")) this.goto("avatars")
                }).catch(this.receive_error).then(() => {
                    this.loading = false
                })
            }, this.autofill_timeout) // need a timeout slightly longer than input field's debounce. Otherwise, Enter key happens before this.field is updated!
        }
    },
    data () {
        return {
            access_code: ""
        }
    }
}
</script>
