<template>
    <modal-dialog
        id="group-editor"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <span class="h5">
                {{ $root.translate("New Group") }}
            </span>
        </template>
        <base-form
            v-if="!!record"
            :has-required-fields="true"
            @submit.prevent.stop="submit"
        >
            <div class="columns">
                <div class="column col-4 col-sm-12">
                    <avatar-input
                        id="avatar_id"
                        name="avatar_id"
                        v-model="record.avatar_id"
                        @input="save"
                    />
                </div>
                <div class="column col-8 col-sm-12">
                    <form-input
                        id="name"
                        name="name"
                        v-model="record.name"
                        :required="true"
                        :error="has_error('name')"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Name") }}</template>
                    </form-input>

                    <form-select
                        id="curriculum_id"
                        name="curriculum_id"
                        v-model="record.curriculum_id"
                        :context-id="profile.state"
                        data-source="group"
                        :required="true"
                        :show-all="true"
                    >
                        <template v-slot:label>{{ $root.translate("Curriculum") }}</template>
                    </form-select>

                    <form-dropdown
                        id="grade"
                        name="grade"
                        :value="record.grade"
                        :options="available_grades"
                        :required="false"
                        :error="has_error('grade')"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Grade") }}</template>
                    </form-dropdown>

                    <div class="text-center mt-8">
                        <default-button
                            class="mr-2"
                            @click.prevent="shown = false"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                        <default-button
                            color="primary"
                            class="ml-2"
                            @click.prevent="create"
                        >
                            {{ $root.translate("Create") }}
                        </default-button>
                    </div>
                </div>
            </div>
        </base-form>
    </modal-dialog>
</template>

<script>
import handles_errors from "@/nibnut/mixins/HandlesErrors"
import handles_grades from "@/custom/mixins/HandlesGrades"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseForm from "@/nibnut/components/BaseForm"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormSelect from "@/nibnut/components/Inputs/FormSelect"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import AvatarInput from "@/custom/components/Inputs/AvatarInput"

export default {
    name: "NewGroupDialog",
    mixins: [handles_errors, handles_grades],
    components: {
        ModalDialog,
        BaseForm,
        FormInput,
        FormSelect,
        FormDropdown,
        DefaultButton,
        AvatarInput
    },
    methods: {
        save (value, field) {
            if(this.record) {
                if(this.record[field] !== value) this.record[field] = value
            }
            return Promise.resolve()
        },
        create () {
            this.$store.dispatch(
                "CREATE_RECORD",
                {
                    entity: "group",
                    data: {
                        ...this.record,
                        relation_ids: ["group_user"]
                    }
                }
            ).then(record => {
                this.$emit("created", record)
                this.shown = false
            }).catch(this.receive_error)
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        record: {
            type: Object,
            required: true
        }
    }
}
</script>
