<template>
    <div>
        <address-input
            v-if="!role_is_at_least_administrator(user.role)"
            id="address"
            name="address"
            :value="user"
            :fixed-country="true"
            :required="true"
            :editable="!creating"
            :error="has_error('address')"
            class="mt-4"
        >
            <template v-slot:label>{{ translate("Billing Address") }}</template>
        </address-input>

        <form-toggle-input
            v-if="!!user.country && (user.country !== 'CA')"
            id="certified_at"
            name="certified_at"
            type="checkbox"
            :value="!!user.certified_at"
            :error="has_error('certified_at')"
            @input="user.certified_at ? null : $moment()"
        >
            {{ translate("I certify that I am not resident in Canada for purposes of the Excise Tax Act. Where applicable, I agree to keep my profile's address information up to date for purposes of the Excise Tax Act.") }}
        </form-toggle-input>

        <h4 v-if="!!joining" class="my-4">
            {{ translate("Signing up as collaborator: FREE") }}
        </h4>
        <subscription-pricing-table
            v-else
            :applied-coupon-code="coupon_code"
            :auto-applied="coupon_auto_applied"
            :min-seats="minimumSeats"
            :billing-country="user.country"
            :billing-province="user.state"
            :invitation-token="user.invitation_token"
            class="my-4"
            @input="save_payment_data"
        />

        <div v-if="!joining" class="columns mb-4">
            <div
                v-if="!coupon_auto_applied"
                :class="{ 'col-6': !!owed_amount, 'col-12': !owed_amount }"
                class="column col-sm-12"
            >
                <coupon-code-input
                    v-if="!!coupon || !!owed_amount"
                    :coupon="coupon"
                    class="mb-4"
                    :editable="!creating"
                    @input="coupon_code = $event ? $event.stripe_id : null"
                />
            </div>
            <div
                v-if="!!owed_amount"
                :class="{ 'col-6': !coupon_auto_applied, 'col-12': !!coupon_auto_applied }"
                class="column col-sm-12"
            >
                <credit-card-input
                    id="gateway_token"
                    :owed-amount="owed_amount"
                    :editable="!creating"
                />
            </div>
        </div>

        <form-toggle-input
            id="terms_accepted_at"
            name="terms_accepted_at"
            type="checkbox"
            :value="!!user.terms_accepted_at"
            :required="true"
            @input="user.terms_accepted_at ? null : $moment()"
        >
            <span v-html="terms_label"></span>
        </form-toggle-input>
        <form-toggle-input
            id="is_adult"
            name="is_adult"
            type="checkbox"
            :value="!!user.is_adult"
            :error="has_error('is_adult')"
            @input="user.is_adult = $event"
        >
            {{ translate("I have reached the age of majority in my province or territory of residence.") }}
        </form-toggle-input>

        <feedback-button
            :status="feedback_status"
            :disabled="!!creating"
            :waiting="creating === 'stripe'"
            :single-use="false"
            color="primary"
            :block="true"
            size="lg"
            @click.prevent="signup"
        >
            <template v-slot:message>
                <div
                    :class="{ 'text-success': (feedback_status === true), 'text-error': (feedback_status === false) }"
                >
                    {{ feedback_message }}
                </div>
            </template>
            <span v-if="!!owed_amount">{{ translate("Sign up with Credit Card") }}</span>
            <span v-else>{{ translate("Sign up") }}</span>
        </feedback-button>

        <vue-recaptcha
            ref="recaptcha"
            :sitekey="recaptcha_key"
            :load-recaptcha-script="true"
            size="invisible"
            @verify="verify_recaptcha"
            class="recaptcha my-3"
        />
    </div>
</template>

<script>
import handles_errors from "@/nibnut/mixins/HandlesErrors"
import handles_recaptcha from "@/nibnut/mixins/HandlesRecaptcha"
import handles_payments from "@/custom/mixins/HandlesPayments"
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import string_utilities from "@/custom/mixins/StringUtilities"

import AddressInput from "@/nibnut/components/Inputs/AddressInput/AddressInput"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import SubscriptionPricingTable from "@/custom/components/Inputs/SubscriptionPricingTable"
import CouponCodeInput from "@/custom/components/Inputs/CouponCodeInput"
import CreditCardInput from "@/custom/components/Inputs/CreditCardInput"
import FeedbackButton from "@/nibnut/components/Buttons/FeedbackButton"
import VueRecaptcha from "vue-recaptcha"

export default {
    name: "SignupPay",
    mixins: [handles_errors, handles_recaptcha, handles_payments, profile_utilities, string_utilities],
    components: {
        AddressInput,
        FormToggleInput,
        SubscriptionPricingTable,
        CouponCodeInput,
        CreditCardInput,
        FeedbackButton,
        VueRecaptcha
    },
    mounted () {
        this.auto_apply_coupon()
        this.setup_taxes()
    },
    watch: {
        "user.country": "setup_taxes",
        "user.province": "setup_taxes",
        privateCouponCode: "auto_apply_coupon"
    },
    methods: {
        auto_apply_coupon () {
            this.coupon_code = this.privateCouponCode || this.setting("auto_apply_coupon_code")
        },
        setup_taxes () {
            if(this.user) {
                this.country = this.user.country
                this.province = this.user.province
            }
        },
        set_creating_flag (value) {
            this.user.gateway = value || null
            this.$emit("creating", value)
        },
        account_created () {
            this.$emit("input", "")
            this.user.first_name = ""
            this.user.last_name = ""
            this.user.password = ""
            this.user.institution_id = 0
            this.user.line1 = ""
            this.user.line2 = ""
            this.user.city = ""
            this.user.state = ""
            this.user.country = "CA"
            this.user.zip = ""
            this.user.gateway = null
            this.user.gateway_token = ""
            this.user.invitation_token = ""
            this.user.subscribed_to_newsletter = true
            this.user.terms_accepted_at = null
            this.user.certified_at = null

            this.qty = this.minimumSeats
            this.coupon = null

            this.recaptcha = ""

            this.$emit("dismiss")
            window.location.search = ""
            this.set_creating_flag(false)
            this.$emit("subscribed")
        },
        create_account () {
            return new Promise((resolve, reject) => {
                const signup = () => {
                    this.clear_all_errors()
                    const { recaptcha, password, ...data } = this.user
                    data.recaptcha = this.recaptcha
                    data.password = encodeURIComponent(this.user.password)
                    data.qty = this.qty
                    data.coupon_code = this.coupon_code
                    data.certified_at = data.certified_at ? data.certified_at.format("YYYY-MM-DD") : null
                    data.join = this.joining

                    this.$store.dispatch("SIGNUP", {
                        data: {
                            ...data,
                            language: this.$i18n.locale() || "en"
                        },
                        login: true
                    }).then(() => {
                        this.account_created()
                    }).catch(error => {
                        if(error) {
                            if(error.topic_id && (error.topic_id === "invitation_token")) {
                                this.already_invited = error.message
                            } else {
                                this.receive_error(error)

                                if((error.topic_id === "institution_id") || (error.topic_id === "address") || (error.topic_id === "certified_at") || (error.topic_id === "is_adult")) this.$emit("goto", "affiliation", this.errors)
                                else if(
                                    (error.topic_id === "email") ||
                                    (error.topic_id === "password") ||
                                    (error.topic_id === "first_name") ||
                                    (error.topic_id === "last_name") ||
                                    (error.topic_id === "subscribed_to_newsletter")
                                ) this.$emit("goto", "info", this.errors)
                            }
                        }
                        this.set_creating_flag(false)
                        reject(error)
                    })
                }
                if(this.$refs.recaptcha && (this.user.gateway === "stripe")) {
                    this.verification_callback = () => {
                        if(this.recaptcha) signup()
                        this.verification_callback = null
                    }
                    this.$refs.recaptcha.execute()
                } else signup()
            })
        },
        signup () {
            this.set_creating_flag("stripe")
            const signup = () => {
                this.create_account().then(() => {
                    this.set_creating_flag(false)
                }).catch(() => {
                    this.set_creating_flag(false)
                })
            }
            setTimeout(() => {
                if(!this.owed_amount) {
                    this.user.gateway_token = ""
                    signup()
                } else {
                    this.tokenize_payment(this.user).then(({ gateway_token }) => {
                        this.user.gateway_token = gateway_token
                        signup()
                    }).catch(error => {
                        this.$error(error.message)
                        this.set_creating_flag(false)
                    })
                }
            }, this.autofill_timeout) // need a timeout slightly longer than input field's debounce. Otherwise, Enter key happens before this.field is updated!
        }
    },
    computed: {
        terms_label () {
            const link = (id, label) => {
                const urls = this.setting("urls")
                let link = label
                if(!!urls && !!urls[id]) {
                    const href = this.translate_url(urls[id])
                    link = `<a href="${href}" title="${link}" target="_terms">${link}</a>`
                }
                return link
            }
            const privacy_link = link("privacy", this.translate("Privacy Policy"))
            const terms_link = link("terms", this.translate("Terms and Conditions of Use"))
            const copyright_link = link("copyright", this.translate("Copyright Policy"))
            return this.translate("I have read, understood and accepted the {privacy_link}, the {terms_link} and the {copyright_link}.", { privacy_link, terms_link, copyright_link })
        },
        coupon_auto_applied () {
            const auto_apply_coupon_code = this.setting("auto_apply_coupon_code")
            return (!!this.privateCouponCode && (this.coupon_code === this.privateCouponCode)) || (!!auto_apply_coupon_code && (this.coupon_code === auto_apply_coupon_code))
        },
        joining () {
            const matches = window.location.search.match(/(?:\?|&)join=([^&]+)(?:&|$)/)
            return matches ? matches[1] : null
        },
        owed_amount () {
            return !this.joining && this.plan_price(this.plan, this.coupon, this.taxes)
        }
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        minimumSeats: {
            type: Number,
            required: true
        },
        privateCouponCode: {
            type: String,
            default: ""
        },
        creating: {
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.recaptcha {
    display: flex;
    justify-content: center;
}
</style>
