<template>
    <span>
        <img
            v-if="!!displayed_answer.match(/^https?/i)"
            :src="displayed_answer"
            :class="{ 'border-success': studentAnswer && !!answer.ended_at && answer.computed_success, 'border-error': studentAnswer && !!answer.ended_at && !answer.computed_success, 'border-gray': !studentAnswer }"
        />
        <span
            v-else
            :class="{ 'text-success': !!answer.ended_at && answer.computed_success, 'text-error': !!answer.ended_at && !answer.computed_success }"
        >
            {{ displayed_answer }}
        </span>
    </span>
</template>

<script>
export default {
    name: "Answer",
    computed: {
        displayed_answer () {
            if(!!this.answer && !!this.answer.answer) {
                if((typeof this.answer.answer === "string") && this.answer.answer.match(/^\[/)) return JSON.parse(this.answer.answer).join(" ")
                if(Array.isArray(this.answer.answer)) return this.answer.answer.join(" ")
                if(typeof this.answer.answer !== "string") return Object.values(this.answer.answer).join(" ")
                return this.answer.answer
            }
            return ""
        }
    },
    props: {
        answer: {
            type: Object,
            required: true
        },
        studentAnswer: {
            type: Boolean,
            default: true
        }
    }
}
</script>
