<template>
    <div class="student-login">
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <panel-header
            @click="dismiss"
        >
            L'Univers<br class="show-sm" /><span class="hide-sm">&nbsp;</span>de Sam Amuse
            <div v-if="!!group.name" class="text-small">
                {{ group.name }}
                <default-button
                    flavor="link"
                    size="sm"
                    :title="translate('Use a different group access code')"
                    @click.prevent="logout"
                >
                    <open-icon glyph="backspace" />
                </default-button>
            </div>
        </panel-header>
        <div class="modal-body text-center">
            <div v-if="!!student && !!student.image_url" class="columns">
                <div class="column col-4 col-2x-3 col-lg-2 col-md-4 col-sm-6 col-mx-auto">
                    <img :src="student.image_url" />
                </div>
            </div>
            <h6 v-if="!!student && !!student.username">{{ student.username }}</h6>

            <form-input
                v-if="(group.grade >= 0) && (group.grade >= setting('min_grade_for_text_password'))"
                id="password"
                name="password"
                v-model="password"
                :required="true"
                :error="has_error('password')"
                @input="save"
            >
                <template v-slot:label>{{ translate("Password") }}</template>
            </form-input>
            <form-visual-password-input
                v-else-if="(group.grade >= 0) && (group.grade >= setting('min_grade_for_image_password'))"
                id="password"
                name="password"
                v-model="password"
                :student-id="student.id"
                :required="true"
                :error="has_error('password')"
            >
                <template v-slot:label>{{ translate("Password") }}</template>
            </form-visual-password-input>

            <div class="text-center mt-8">
                <feedback-button
                    :status="feedback_status"
                    :waiting="loading"
                    :single-use="false"
                    color="green"
                    @click.prevent="login"
                >
                    <template v-slot:message>
                        <div
                            :class="{ 'text-success': (feedback_status === true), 'text-error': (feedback_status === false) }"
                        >
                            {{ feedback_message }}
                        </div>
                    </template>
                    {{ translate("Go!") }}
                </feedback-button>
            </div>
        </div>
        <div class="modal-footer">
            <default-button
                id="last_focusable"
                flavor="link"
                size="sm"
                @click.prevent="goto('avatars')"
            >
                {{ translate("Oops! That's not me!") }}
            </default-button>
        </div>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import is_panel from "@/nibnut/dialogs/Authentication/IsPanel"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import tracks from "@/custom/mixins/Tracks"
import language_utilities from "@/custom/mixins/LanguageUtilities"

import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormVisualPasswordInput from "@/custom/components/Inputs/FormVisualPasswordInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import FeedbackButton from "@/nibnut/components/Buttons/FeedbackButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import PanelHeader from "./PanelHeader"

export default {
    mixins: [is_panel, misc_utilities, tracks, language_utilities],
    components: {
        FormInput,
        FormVisualPasswordInput,
        DefaultButton,
        FeedbackButton,
        OpenIcon,
        PanelHeader
    },
    mounted () {
        this.group = JSON.parse(this.$cookie.get("group") || "{}")
        if(!this.group.id) this.goto("access-code")
    },
    methods: {
        login () {
            this.loading = true
            this.clear_all_errors()

            this.$store.dispatch(
                "STUDENT_LOGIN",
                { student_id: this.student.id, password: this.password }
            ).then(() => {
                this.langugage_reread()
                if(this.profile_id) {
                    this.password = ""
                    this.$emit("dismiss")
                }
                this.track_event("Login", "Student", this.$cookie.get("lang") || "en")
            }).catch(this.receive_error).then(() => {
                this.loading = false
            })
        },
        reset_language () {
            const language = this.$cookie.get("lang") || "fr"
            this.$i18n.set(language)
        },
        logout () {
            this.$store.dispatch("GROUP_LOGOUT").then(() => {
                if(!this.$cookie.get("group")) this.goto("access-code")
            }).catch(this.receive_error)
        }
    },
    computed: {
    },
    props: {
        student: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            group: {},
            password: ""
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.student-login {
    img {
        width: 100%;
        height: 100%;
    }
}
</style>
