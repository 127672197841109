<template>
    <div class="modal-header columns">
        <app-logo
            :with-text="!is_student_access"
            class="column col-sm-4"
        />
        <div
            class="modal-title column col-sm-8 h5"
        >
            <slot>L'Univers<br class="show-sm" /><span class="hide-sm">&nbsp;</span>de Sam Amuse</slot>
        </div>
        <language-picker
            v-if="!is_student_access"
            class="column"
        />
        <div
            v-if="dismissable"
            class="column"
        >
            <dismiss-link
                id="first_focusable"
                class="btn btn-clear"
                @click="$emit('click')"
            />
        </div>
    </div>
</template>

<script>
import { AppLogo, DismissLink } from "@/nibnut/components"
import { LanguagePicker } from "@/custom/components"

export default {
    name: "PanelHeader",
    components: {
        AppLogo,
        DismissLink,
        LanguagePicker
    },
    computed: {
        is_student_access () {
            return !!this.$route && !!this.$route.meta && !!this.$route.meta.student_route
        }
    },
    props: {
        dismissable: {
            type: Boolean,
            default: false
        }
    }
}
</script>
