<template>
    <div>
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <panel-header
            @click="dismiss"
        >
            <span v-if="fetching">{{ $root.translate("Fetching your invitation...") }}</span>
            <span v-else>{{ $root.translate("Signup") }}</span>
            <div class="mt-4 text-small text-gray no-dups-note">
                {{ $root.translate("NOTE: Do not create many accounts. Please contact Jeux Sam Amuse if you changed email address or are unable to connect to your existing account.") }}
            </div>
        </panel-header>
        <div class="modal-body pt-0">
            <div
                v-if="fetching"
                class="loader-container"
            >
                <loader />
            </div>
            <base-form
                v-else
                :has-required-fields="true"
                @submit.prevent.stop
            >
                <div v-if="small_screen">
                    <accordion
                        :tabs="tabs"
                        :current-tab-id="current_tab_id"
                        class="mt-4 mb-8"
                        @click="goto_tab"
                    >
                        <template v-slot:default="{ tab_id }">
                            <signup-info
                                v-if="(current_tab_id === tab_id) && (tab_id === 'info')"
                                key="info"
                                :user="user"
                                :creating="creating"
                                :dismissable="dismissable"
                                :errors="errors"
                                @input="update_email"
                            />
                            <signup-pay
                                v-else-if="(current_tab_id === tab_id) && (tab_id === 'pay')"
                                :user="user"
                                :minimum-seats="minimum_seats"
                                :private-coupon-code="coupon_code"
                                :creating="creating"
                                @creating="creating = $event"
                                @goto="goto_tab"
                            />
                        </template>
                    </accordion>
                </div>
                <div v-else>
                    <slider-tabs
                        :tabs="tabs"
                        :current-tab-id="current_tab_id"
                        class="mt-4 mb-8"
                        @click="goto_tab"
                    />

                    <transition name="fade" enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
                        <signup-info
                            v-if="current_tab_id === 'info'"
                            :user="user"
                            :selected-institution="selected_institution"
                            :creating="creating"
                            :dismissable="dismissable"
                            :errors="errors"
                            @institution="selected_institution = $event"
                            @input="update_email"
                        />
                        <signup-affiliation
                            v-if="current_tab_id === 'affiliation'"
                            :user="user"
                            :errors="errors"
                            :creating="creating"
                        />
                        <signup-pay
                            v-if="current_tab_id === 'pay'"
                            :user="user"
                            :minimum-seats="minimum_seats"
                            :private-coupon-code="coupon_code"
                            :creating="creating"
                            @creating="creating = $event"
                            @goto="goto_tab"
                            @subscribed="maybe_join_group"
                        />
                    </transition>
                </div>
            </base-form>
        </div>
        <div
            v-if="!user.invitation_token"
            class="modal-footer"
        >
            <default-button
                id="last_focusable"
                flavor="link"
                size="sm"
                @click.prevent="goto('login')"
            >
                {{ $root.translate("I'm already signed-up - let me login") }}
            </default-button>
        </div>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import is_panel from "@/nibnut/dialogs/Authentication/IsPanel"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"

import SignupInfo from "./SignupInfo"
import SignupPay from "./SignupPay"
import Loader from "@/custom/components/Loader"
import Accordion from "@/custom/components/Containers/Accordion"
import SliderTabs from "@/custom/components/Containers/SliderTabs"
import BaseForm from "@/nibnut/components/BaseForm"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import PanelHeader from "./PanelHeader"

export default {
    mixins: [is_panel, misc_utilities, profile_utilities],
    components: {
        SignupInfo,
        SignupPay,
        BaseForm,
        DefaultButton,
        Loader,
        SliderTabs,
        Accordion,
        PanelHeader
    },
    mounted () {
        this.load_data()
    },
    methods: {
        load_data () {
            this.minimum_seats = this.setting("min_subscription_seats")
            if(window.location.search) {
                const matches = window.location.search.match(/(?:\?|&)invitation=([^&]+)(?:&|$)/)
                if(matches) {
                    const token = matches[1]

                    this.fetching = true
                    this.$store.dispatch("LOAD_INVITATION", {
                        token
                    }).then(invitation_data => {
                        this.user.role = invitation_data.role
                        this.user.invitation_token = token
                        this.user.first_name = invitation_data.first_name
                        this.user.last_name = invitation_data.last_name
                        this.user.email = invitation_data.email
                        this.user.institution_id = invitation_data.institution_id
                        this.user.line1 = invitation_data.line1
                        this.user.line2 = invitation_data.line2
                        this.user.city = invitation_data.city
                        this.user.state = invitation_data.state
                        this.user.country = invitation_data.country
                        this.user.zip = invitation_data.zip
                        this.user.subscribed_to_newsletter = true
                        this.user.terms_accepted_at = null
                        if(invitation_data.minimum_seats) this.minimum_seats = invitation_data.minimum_seats
                        this.coupon_code = invitation_data.coupon_code
                    }).catch(this.receive_error).then(() => {
                        this.fetching = false
                    })
                }
            }
        },
        resend_invitation () {
            if(this.user.email) {
                this.$store.dispatch("RECORD_ACTION", { entity: "user", action: "re-invite", data: { email: this.user.email }, passthru: true }).then(() => {
                    this.$success("<strong>" + this.$root.translate("Invitation link re-sent!") + "</strong><br>" + this.$root.translate("Check your inbox... (or junk folder, just in case)"))
                }).catch(error => {
                    this.$error("<strong>" + error.header + "</strong><br>" + error.message)
                })
            }
        },
        tab_is_complete (tab_id) {
            if(tab_id === "info") return !!this.user.first_name && !!this.user.last_name && !!this.user.email && !!this.user.password
            if(tab_id === "affiliation") return !!this.user.line1 && !!this.user.city && !!this.user.state && !!this.user.country && !!this.user.zip
            if(tab_id === "pay") return !!this.qty && (this.qty >= this.user.minimum_seats)
            return false
        },
        goto_tab (tab_id, errors = null) {
            if(errors) this.errors = { ...errors }
            this.current_tab_id = tab_id
        }
    },
    computed: {
        tabs () {
            return [
                { id: "info", label: this.$root.translate("Account"), glyph: "chevron-circle-right" },
                { id: "pay", label: this.$root.translate("Payment"), glyph: "chevron-circle-right" }
            ]
        }
    },
    data () {
        return {
            fetching: false,
            creating: false,
            user: {
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                institution_id: 0,
                line1: "",
                line2: "",
                city: "",
                state: "",
                country: "CA",
                zip: "",

                gateway: null,
                gateway_token: "",

                role: 0,
                invitation_token: "",
                subscribed_to_newsletter: true,
                terms_accepted_at: null,
                certified_at: null,
                is_adult: false
            },
            minimum_seats: 1,
            coupon_code: null,
            errors: {},

            current_tab_id: "info",
            selected_institution: null
        }
    }
}
</script>
