<template>
    <modal-dialog
        id="students-archiver"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <div class="h5">
                {{ $root.translate("Archive Students") }}
                <help-button class="popover-right">
                    <h6>{{ $root.translate("What does 'Archiving a student' do?") }}</h6>
                    <p>{{ $root.translate("When you archive a student, you free-up a seat on your license so you can add new students to your groups instead.") }}</p>
                    <p>{{ $root.translate("Of course, that means you lose access to the archived student's information/account, but the student's information is not deleted, and will still be available to the student or their new teacher(s).") }}</p>
                </help-button>
            </div>

            <div
                v-if="!fetching && (groups.length <= 1)"
                class="h6"
            >
                {{ group.name }}
            </div>
            <form-dropdown
                v-else-if="!fetching"
                id="group_id"
                name="group_id"
                v-model="group_id"
                :options="groups"
                :required="true"
            />
        </template>

        <div
            v-if="fetching"
            class="py-8"
        >
            <loader size="sm" />
        </div>
        <data-table
            v-else
            id="students-archiver-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            :selection="selected_row_ids"
            :show-head="false"
            :show-totals="false"
            @select="select_row"
            @clear-selection="clear_selection"
            @filter-by-selection="filter_by_selection"
            @click="select_row($event.id, selected_row_ids.indexOf($event.id) < 0)"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
        >
            <template
                v-slot:summary="{ row }"
            >
                <img
                    v-if="!!row.image_url"
                    :src="row.image_url"
                    class="mr-2"
                />
                {{ row.username }}
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div
                    v-if="field === 'username'"
                >
                    <img
                        v-if="!!row.image_url"
                        :src="row.image_url"
                        class="mr-2"
                    />
                    {{ row.username }}
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <template v-slot:footer>
            <div v-if="!fetching" class="columns">
                <div
                    v-if="!!archiving_students"
                    class="column col-auto col-sm-12 text-left"
                >
                    <default-button
                        flavor="link"
                        :waiting="waiting === 'dont-ask'"
                        :disabled="!!waiting"
                        @click.prevent="archive('dont-ask')"
                    >
                        {{ $root.translate("Don't ask me again") }}
                    </default-button>
                </div>
                <div
                    class="column col-sm-12"
                >
                    <default-button
                        v-if="!!archiving_students"
                        :waiting="waiting === 'later'"
                        :disabled="!!waiting"
                        @click.prevent="archive('later')"
                    >
                        {{ $root.translate("Ask me later") }}
                    </default-button>
                    <default-button
                        v-else
                        :disabled="!!waiting"
                        @click.prevent="shown = false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        :waiting="waiting === 'archive'"
                        :disabled="!!waiting || !selected_row_ids.length"
                        class="ml-2"
                        @click.prevent="archive('archive')"
                    >
                        {{ $root.translate("Archive Student(s)") }}
                    </default-button>
                </div>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import orderBy from "lodash/orderBy"

import is_data_table_source from "@/nibnut/mixins/IsDataTableSource"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import HelpButton from "@/nibnut/components/Buttons/HelpButton"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import Loader from "@/custom/components/Loader"

export default {
    name: "StudentsArchiver",
    components: {
        ModalDialog,
        HelpButton,
        FormDropdown,
        DefaultButton,
        Loader
    },
    mixins: [is_data_table_source, addl_profile_utilities],
    mounted () {
        this.load()
    },
    watch: {
        archiving_students: "load"
    },
    methods: {
        load () {
            if(this.is_at_least_teacher && this.profile.is_subscribed && (this.archiving_students !== null) && this.profile.groups_to_archive && !!this.profile.groups_to_archive.length) {
                this.fetching = true
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "group",
                        query: {
                            ids: this.profile.groups_to_archive,
                            relation_ids: ["group_user"],
                            fields: ["fieldset::archiver", "ns::group_user;fieldset::archiver"]
                        }
                    }
                ).then(() => {
                    const groups = this.groups
                    if(!groups || !groups.length) this.shown = false
                    else this.set_group_id(groups[0].id)
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.fetching = ""
                })
            }
        },
        set_group_id (group_id) {
            this.group_id = group_id
            this.selected_row_ids = this.rows.map(group_user => group_user.id)
        },
        archive (action_id) {
            this.waiting = action_id
            let ids = this.selected_row_ids
            if(action_id === "dont-ask") ids = 0
            else if(action_id === "later") ids = 1

            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "group",
                    id: this.group_id,
                    action: "archive-students",
                    data: {
                        ids,
                        relation_ids: ["group_user", "user"],
                        fields: ["fieldset::archiver", "ns::group_user;fieldset::archiver", "ns::user;fieldset::profile"]
                    },
                    method: "post"
                }
            ).then(() => {
                if((action_id === "archive") && !!this.raw_groups.length) this.set_group_id(this.groups[0].id)
                else this.shown = false
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.waiting = false
            })
        }
    },
    computed: {
        ...mapState(["archiving_students"]),
        ...mapGetters(["entity_records"]),
        shown: {
            get () {
                return this.archiving_students !== null
            },
            set (show) {
                this.$store.dispatch("SHOW_STUDENT_ARCHIVER", { show: show ? false : null })
            }
        },
        raw_groups () {
            if(!this.profile || !this.profile.groups_to_archive) return []
            return this.entity_records("group").filter(group => {
                return this.profile.groups_to_archive.indexOf(group.id) >= 0
            })
        },
        groups () {
            return orderBy(this.raw_groups, "name", "asc")
        },
        group () {
            if(!this.group_id) return {}
            return this.raw_groups.find(group => group.id === this.group_id) || {}
        },
        rows () {
            if(!this.group_id) return []
            const student_role = this.constants("group_user_types", "ROLE_STUDENT").id
            return this.entity_records("group_user").filter(group_user => {
                return (group_user.role === student_role) && (group_user.group_id === this.group_id)
            })
        }
    },
    props: {
    },
    data () {
        return {
            fetching: false,
            group_id: 0,
            waiting: "",

            columns: {
                username: { label: this.translate("Student"), sort: "asc", type: "alpha" }
            },
            selected_row_ids: []
        }
    }
}
</script>

<style lang="scss">
#students-archiver {
    table.table {
        img {
            max-height: 1em;
        }
    }
}
</style>
