<template>
    <modal-dialog
        id="replay-dialog"
        :show.sync="shown"
    >
        <template v-slot:title>
            <div v-if="!!assignment && !!assignment.id" class="columns">
                <div class="column col-6">
                    <div class="student-info">
                        <avatar :student="student" />
                        <div class="ml-2">
                            <div>{{ student.username }}</div>
                            <div v-if="assignment.stuck" class="bg-warning text-bold text-small">{{ $root.translate("Student appears to be stuck") }}</div>
                            <div v-else-if="assignment.computed_in_progress" class="bg-success text-bold text-small">{{ $root.translate("In Progress...") }}</div>
                            <div v-else class="text-gray text-small">{{ assignment.computed_last_seen_at | nibnut.date("ll") }}</div>
                        </div>
                    </div>
                </div>

                <div class="column col-6 text-right">
                    <div>
                        <span class="dot" :style="{ 'background-color': subject.color }"></span>{{ subject.name }}
                    </div>
                    <div class="text-gray text-small">
                        {{ $root.translate("Level {number}", { number: assignment.current_level }) }}<span v-if="!!assignment.computed_last_seen_at">&nbsp;&bull;&nbsp;{{ success | nibnut.number("0,0") }}&nbsp;%</span>
                    </div>
                </div>
            </div>
            <div v-if="!!assignment && !!assignment.id" class="columns mt-4 mb-2">
                <div v-if="!!achievements.length" class="column col-12 text-gray">
                    {{ achievements.join(", ") }}
                </div>
            </div>
        </template>

        <div v-if="!!assignment && !!assignment.id" class="timeline">
            <div class="text-right">
                <div class="btn-group">
                    <default-button
                        :active="!filter"
                        size="sm"
                        @click.prevent="filter = ''"
                    >
                        {{ $root.translate('All Answers') }}
                    </default-button>
                    <default-button
                        :active="filter === 'wrong'"
                        size="sm"
                        @click.prevent="filter = 'wrong'"
                    >
                        {{ $root.translate('Wrong Answers') }}
                    </default-button>
                    <default-button
                        :active="filter === 'good'"
                        size="sm"
                        @click.prevent="filter = 'good'"
                    >
                        {{ $root.translate('Good Answers') }}
                    </default-button>
                </div>
            </div>
            <div
                v-for="answer in filtered_answers"
                :key="answer.id"
                class="timeline-item mt-4"
            >
                <div class="timeline-left">
                    <span
                        :class="{ 'bg-success': !!answer.ended_at && answer.computed_success, 'bg-error': !!answer.ended_at && !answer.computed_success }"
                        class="timeline-icon icon-lg"
                    >
                        <open-icon v-if="!!answer.ended_at && answer.computed_success" glyph="check" />
                        <open-icon v-else-if="!!answer.ended_at && !answer.computed_success" glyph="times" />
                    </span>
                </div>
                <div class="timeline-content">
                    <div class="tile">
                        <div class="tile-content">
                            <div class="tile-title"><strong>Q.</strong> {{ question_by_id(answer.question_id).label }}</div>
                            <div
                                v-if="answer.stuck"
                                class="tile-title text-warning"
                            >
                                {{ $root.translate("Student appears to be stuck") }}
                            </div>
                            <div
                                v-else-if="!answer.ended_at"
                                class="tile-title text-gray"
                            >
                                {{ $root.translate("In progress...") }}
                            </div>
                            <div
                                v-else
                                :class="{ 'text-success': !!answer.ended_at && answer.computed_success, 'text-error': !!answer.ended_at && !answer.computed_success }"
                                class="tile-title"
                            >
                                <strong class="mr-2">A.</strong>
                                <answer
                                    :answer="answer"
                                />
                            </div>
                            <small class="tile-subtitle text-gray">
                                {{ answer_duration(answer) }}
                                &nbsp;&bull;&nbsp;
                                <span v-if="answer.repeat">
                                    {{ translate("Question repeated {n} time(s)", { n: answer.repeat }) }}
                                    &nbsp;&bull;&nbsp;
                                </span>
                                {{ $root.translate("Good Answer") }}:
                                <answer
                                    :answer="question_by_id(answer.question_id)"
                                    :student-answer="false"
                                />
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template v-slot:footer>
            <div class="text-center">
                <default-button
                    @click.prevent="shown = false"
                >
                    {{ $root.translate("Done") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import Avatar from "@/custom/components/Avatar"
import Answer from "./Answer"

export default {
    name: "ReplayDialog",
    mixins: [misc_utilities],
    components: {
        ModalDialog,
        DefaultButton,
        OpenIcon,
        Avatar,
        Answer
    },
    mounted () {
        this.reset()
    },
    watch: {
        show: "reset"
    },
    methods: {
        reset () {
            if(this.show) {
                this.fetching = true
                this.$store.dispatch(
                    "FETCH_RECORD",
                    {
                        entity: "assignment",
                        id: this.assignmentId,
                        query: {
                            fields: ["fieldset::replay", "ns::question;fieldset::replay", "ns::answer;fieldset::replay"],
                            relation_ids: ["question", "answer"]
                        }
                    }
                ).then(() => {
                    this.filter = ""
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.fetching = false
                })
            }
        },
        question_by_id (id) {
            return this.entity_record("question", id)
        },
        answer_duration (answer) {
            const end_date = answer.ended_at ? this.$moment(answer.ended_at) : this.$moment()
            const seconds = end_date.diff(this.$moment(answer.started_at), "seconds")
            if(seconds < 60) return this.$root.translate("{number} sec", { number: seconds })
            const minutes = Math.round(seconds / 60)
            if(minutes < 60) return this.$root.translate("{number} min", { number: minutes })
            const hours = minutes / 60
            return this.$root.translate("{number} h", { number: this.nibnut_filter("nibnut.number", [hours, "0,0.0"]) })
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"]),
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        assignment () {
            return this.entity_record("assignment", this.assignmentId)
        },
        subjects () {
            const taxonomy = this.constants("taxonomies", "TAXONOMY_SUBJECT").id
            return orderBy(this.entity_records("tag").filter(tag => tag.taxonomy === taxonomy), this.translated_field_name("name"), "asc")
        },
        subject () {
            if(!this.assignment || !this.assignment.computed_subject_tag_id) return {}
            return this.subjects.find(subject => subject.id === this.assignment.computed_subject_tag_id) || {}
        },
        answers () {
            return orderBy(this.entity_records("answer").filter(answer => answer.assignment_id === this.assignmentId), "started_at", "asc")
        },
        filtered_answers () {
            if(!this.filter) return this.answers
            return this.answers.filter(answer => {
                return (!!answer.computed_success && (this.filter === "good")) || (!answer.computed_success && (this.filter === "wrong"))
            })
        },
        success () {
            const total_answers = this.assignment.computed_good_answers + this.assignment.computed_bad_answers
            const good_answers = total_answers ? Math.round((this.assignment.computed_good_answers / total_answers) * 100) : 0
            return good_answers
        },
        achievements () {
            return this.assignment.achievements || []
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        student: { // group user
            type: Object,
            required: true
        },
        assignmentId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            fetching: false,
            filter: ""
        }
    }
}
</script>
