<template>
    <modal-dialog
        id="custom-date-range-dialog"
        :show.sync="shown"
    >
        <template v-slot:title>
            <span class="h5">{{ $root.translate("Custom Date Range") }}</span>
        </template>

        <div v-if="!!range" class="columns">
            <div class="column col-6">
                <label
                    class="form-label"
                >
                    {{ $root.translate("From") }}
                </label>
                <base-date-input
                    id="from"
                    name="from"
                    v-model="range.from"
                    position=""
                    :required="true"
                />
            </div>
            <div class="column col-6">
                <label
                    class="form-label"
                >
                    {{ $root.translate("To") }}
                </label>
                <base-date-input
                    id="to"
                    name="to"
                    v-model="range.to"
                    position=""
                    :required="true"
                />
            </div>
        </div>

        <template v-slot:footer>
            <div class="text-center">
                <default-button
                    class="mr-2"
                    @click.prevent="$emit('close')"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    color="primary"
                    class="ml-2"
                    @click.prevent="$emit('save')"
                >
                    {{ $root.translate("Save") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseDateInput from "@/nibnut/components/Inputs/BaseDateInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "CustomDateRangeDialog",
    components: {
        ModalDialog,
        BaseDateInput,
        DefaultButton
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        range: {
            type: Object,
            validator: prop => !prop || (typeof prop === "object")
        }
    }
}
</script>
