<template>
    <div>
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <panel-header
            @click="dismiss"
        >
            L'Univers<br class="show-sm" /><span class="hide-sm">&nbsp;</span>de Sam Amuse
            <div v-if="!!group.name" class="text-small">
                {{ group.name }}
                <default-button
                    flavor="link"
                    size="sm"
                    :title="$root.translate('Use a different group access code')"
                    @click.prevent="logout"
                >
                    <open-icon glyph="backspace" />
                </default-button>
            </div>
        </panel-header>
        <div class="modal-body">
            <div class="avatars-list">
                <div
                    v-for="student in students"
                    :key="student.id"
                >
                    <default-button
                        flavor="link"
                        :title="student.username"
                        :disabled="student.logged_in"
                        class="login-button"
                        @click.prevent="pick(student)"
                    >
                        <img
                            :src="student.image_url"
                            :alt="student.username"
                        />
                        <h6 v-if="!!student && !!student.username">{{ student.username }}</h6>
                    </default-button>
                    <default-button
                        v-if="student.logged_in"
                        flavor="link"
                        :title="translate('Logout')"
                        size="sm"
                        class="logout-button"
                        @click.prevent="confirm_student_logout(student)"
                    >
                        <open-icon glyph="unlock-alt" />
                    </default-button>
                </div>
            </div>

            <modal-dialog
                id="confirm-force-logout"
                :show.sync="confirming"
            >
                <p>{{ translate("To insure good statistics, please make sure there is not another student logged in this account by mistake.") }}</p>
                <form-input
                    id="confirmed_name"
                    name="confirmed_name"
                    v-model="confirmed_name"
                    :live="true"
                    :required="false"
                >
                    <template v-slot:label>{{ translate("To confirm the logout, please enter the student's name \"{name}\":", { name: active_student.username }) }}</template>
                </form-input>

                <template v-slot:footer>
                    <div class="text-center">
                        <default-button
                            class="mr-2"
                            @click.prevent="confirming = false"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                        <default-button
                            :disabled="!student_logout_confirmed"
                            color="error"
                            class="ml-2"
                            @click.prevent="student_logout"
                        >
                            {{ $root.translate("Logout") }}
                        </default-button>
                    </div>
                </template>
            </modal-dialog>
        </div>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import is_panel from "@/nibnut/dialogs/Authentication/IsPanel"
import language_utilities from "@/custom/mixins/LanguageUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import PanelHeader from "./PanelHeader"

let heartbeat = null

export default {
    mixins: [is_panel, language_utilities],
    components: {
        ModalDialog,
        FormInput,
        DefaultButton,
        OpenIcon,
        PanelHeader
    },
    mounted () {
        this.load()
    },
    beforeDestroy () {
        if(heartbeat) {
            clearInterval(heartbeat)
            heartbeat = null
        }
    },
    methods: {
        refresh () {
            return this.$store.dispatch(
                "FETCH_RECORDS",
                {
                    entity: "group_user",
                    query: {
                        fields: ["fieldset::login"]
                    }
                }
            )
        },
        load () {
            this.group = JSON.parse(this.$cookie.get("group") || "{}")
            if(!this.group.id) this.goto("access-code")
            else {
                this.loading = true
                this.refresh().then(() => {
                    heartbeat = setInterval(this.refresh, 30 * 1000)
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.loading = false
                })
            }
        },
        pick (student) {
            if(this.group.requires_password) this.$emit("pick", student)
            else {
                this.$store.dispatch(
                    "STUDENT_LOGIN",
                    { student_id: student.id }
                ).then(record => {
                    this.langugage_reread()
                    if(this.profile_id) this.$emit("dismiss")
                }).catch(error => {
                    this.$error(error.message)
                })
            }
        },
        confirm_student_logout (student) {
            this.active_student = student
            this.confirmed_name = ""
            this.confirming = true
        },
        student_logout () {
            if(this.student_logout_confirmed) {
                this.$store.dispatch("STUDENT_LOGOUT", { student_id: this.active_student.id }).catch(this.receive_error).then(() => {
                    this.confirming = false
                })
            }
        },
        logout () {
            this.$store.dispatch("GROUP_LOGOUT").then(() => {
                if(!this.$cookie.get("group")) this.goto("access-code")
            }).catch(this.receive_error)
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        students () {
            const group_id = this.group.id
            return orderBy(this.entity_records("group_user").filter(group_user => group_user.group_id === group_id), "username", "asc")
        },
        student_logout_confirmed () {
            return !!this.active_student && !!this.active_student.username && !!this.confirmed_name && (this.active_student.username.toLowerCase() === this.confirmed_name.toLowerCase())
        }
    },
    data () {
        return {
            group: {},
            loading: true,

            confirming: false,
            active_student: {},
            confirmed_name: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.avatars-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 - $layout-spacing;

    & > div {
        position: relative;
        & > .login-button {
            margin: $layout-spacing;
            width: 150px;
            height: 150px;
            @media(max-width:$size-sm) {
                width: 90px;
                height: 90px;
            }

            img {
                width: 100%;
                height: 100%;
            }
            h6 {
                position: absolute;
                left: 0;
                bottom: $control-padding-y;
                width: 100%;
                margin: 0;
                background-color: rgba(white, 0.7);
            }

            &:focus,
            &:active,
            &.active {
                border-color: transparent;
            }
            @include hover-supported {
                border-color: transparent;
            }
        }
        & > .logout-button {
            position: absolute;
            left: -0.3rem;
            bottom: 0.65rem;
            height: auto;
            line-height: normal;
            padding: 0;
        }
    }
}
.modal#confirm-force-logout {
    font-size: 1rem;
}
</style>
