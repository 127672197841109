<template>
    <div
        :class="{ 'active': !!shown, 'modal-md': ((current_panel_id === 'signup') || (small_screen && (current_panel_id === 'student-login'))), 'modal-lg': (current_panel_id === 'avatars') }"
        class="modal custom-authentication"
    >
        <dismiss-link
            @click="dismiss"
            class="modal-overlay"
        />
        <div class="modal-container">
            <keep-alive>
                <div>
                    <transition name="slide" enter-active-class="animate__animated animate__slideInLeft" leave-active-class="animate__animated animate__slideOutLeft" mode="out-in">
                        <login
                            v-if="current_panel_id==='login'"
                            v-model="email"
                            :label-less="labelLess"
                            :dismissable="dismissable"
                            @goto="current_panel_id=$event"
                            @dismiss="dismiss"
                        />
                        <access-code
                            v-if="current_panel_id==='access-code'"
                            v-model="email"
                            :label-less="labelLess"
                            :dismissable="dismissable"
                            @goto="current_panel_id=$event"
                            @dismiss="dismiss"
                        />
                        <avatars
                            v-if="current_panel_id==='avatars'"
                            v-model="email"
                            :label-less="labelLess"
                            :dismissable="dismissable"
                            @pick="pick_student"
                            @goto="current_panel_id=$event"
                            @dismiss="dismiss"
                        />
                        <student-login
                            v-if="current_panel_id==='student-login'"
                            v-model="email"
                            :label-less="labelLess"
                            :dismissable="dismissable"
                            :student="student"
                            @goto="current_panel_id=$event"
                            @dismiss="dismiss"
                        />
                        <signup
                            v-if="can_signup && (current_panel_id==='signup')"
                            v-model="email"
                            :label-less="labelLess"
                            :dismissable="dismissable"
                            @goto="current_panel_id=$event"
                            @dismiss="dismiss"
                        />
                        <lost-password
                            v-if="current_panel_id==='password'"
                            v-model="email"
                            :label-less="labelLess"
                            :dismissable="dismissable"
                            @goto="current_panel_id=$event"
                            @dismiss="dismiss"
                        />
                        <reset-password
                            v-if="current_panel_id==='reset'"
                            v-model="email"
                            :label-less="labelLess"
                            :dismissable="dismissable"
                            @goto="current_panel_id=$event"
                            @dismiss="dismiss"
                        />
                    </transition>
                </div>
            </keep-alive>
        </div>
    </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import ui_utilities from "@/nibnut/mixins/UiUtilities"

import LostPassword from "@/nibnut/dialogs/Authentication/LostPassword"
import ResetPassword from "@/nibnut/dialogs/Authentication/ResetPassword"
import { DismissLink } from "@/nibnut/components"
import Login from "./Login"
import Signup from "./Signup"
import AccessCode from "./AccessCode"
import Avatars from "./Avatars"
import StudentLogin from "./StudentLogin"

export default {
    mixins: [profile_utilities, ui_utilities],
    components: {
        Login,
        Signup,
        AccessCode,
        Avatars,
        StudentLogin,
        LostPassword,
        ResetPassword,
        DismissLink
    },
    watch: {
        profile_id: "maybe_dismiss",
        "app_context.settings": "maybe_switch_panel",
        "login_request.panel_id": "maybe_switch_panel"
    },
    methods: {
        dismiss () {
            if(this.dismissable) this.$store.dispatch("UNREQUEST_LOGIN")
        },
        maybe_dismiss () {
            if(this.profile_id) {
                this.ready = false
                this.shown = false
            }
        },
        maybe_switch_panel () {
            if(this.shown && !this.ready && !!this.app_context && !!this.app_context.settings) {
                this.ready = true
                if(this.login_request.panel_id === true) {
                    if(this.is_student_access) {
                        const group = this.$cookie.get("group")
                        if(group) this.current_panel_id = "avatars"
                        else {
                            const panel_id = this.$cookie.get("login-mode")
                            if(!!panel_id && !!panel_id.match(/^(access-code|login)$/i)) this.current_panel_id = panel_id
                            else this.current_panel_id = "access-code"
                        }
                    } else {
                        if(this.can_signup && !this.is_app_user) this.current_panel_id = "signup"
                        else this.current_panel_id = "login"
                    }
                } else this.current_panel_id = this.login_request.panel_id
            }
        },
        pick_student (student) {
            this.student = student
            this.current_panel_id = "student-login"
        }
    },
    computed: {
        ...mapState(["profile_id", "login_request", "app_context"]),
        shown: {
            get () {
                return !this.profile_id && !!this.login_request && !!this.login_request.panel_id
            },
            set (show) {
                if(!show) this.dismiss()
            }
        },
        is_student_access () {
            return !!this.$route && !!this.$route.meta && !!this.$route.meta.student_route
        },
        can_signup () {
            return (!!window.location.search && !!window.location.search.match(/(?:\?|&)invitation=([^&]+)(?:&|$)/)) || (!!this.app_context && !!this.app_context.settings && !!this.app_context.settings.signups)
        },
        dismissable () {
            if(this.$route.meta) return !this.$route.meta.admin_route && !this.$route.meta.login_required
            // eslint-disable-next-line
            return !!process.env.VUE_APP_DEFAULT_PUBLIC_PATH && process.env.VUE_APP_DEFAULT_PUBLIC_PATH.match(/^\//)
        }
    },
    props: {
        labelLess: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            current_panel_id: "",
            email: "",
            student: {},
            ready: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.modal.custom-authentication {
    & > .modal-container {
        position: relative;
        max-height: 85vh;
        @media(max-width:$size-sm) {
            padding: 0;
        }

        & > div { overflow-x: hidden; }

        & > div > div {
            overflow: auto;
            animation-duration: 0.35s;

            .modal-header {
                position: relative;
                align-items: center;
                text-align: center;
                padding-top: 2rem;
                padding-bottom: 0;
                margin: 0 auto;
                @media (max-width: $size-sm) {
                    flex-wrap: nowrap;
                }

                .no-dups-note {
                    line-height: 1.5em;
                    padding-left: $unit-16;
                    padding-right: $unit-16;
                    @media(max-width:$size-sm) {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
                .text-small {
                    font-size: 0.7em;
                    font-weight: normal;
                }

                & > .app-logo {
                    flex: 0 0 4.5rem;
                    max-height: 4.5rem;
                    @media (max-width: $size-sm) {
                        width: auto;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: $unit-2;
                        margin-bottom: $unit-4;
                    }
                }
                & > .language-picker {
                    flex: 0 0 4.5rem;
                    @media (max-width: $size-sm) {
                        flex: 0 0 100%;
                        margin-top: $unit-4;
                        margin-bottom: $unit-4;
                    }
                }
                .modal-title.h5 {
                    & > .btn.btn-link {
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translate(-50%, 0);
                    }
                }
            }
            .modal-body {
                & > .loader-container {
                    text-align: center;

                    & > .loader {
                        margin-top: 3rem;
                        margin-bottom: 3rem;
                    }
                }
            }
            .modal-footer {
                text-align: center;
            }
        }

        @media(max-width:$size-sm) {
            & > div > div {
                .modal-header {
                    text-align: center;
                    padding-bottom: $unit-2;
                }
            }
        }
    }
    &.modal-md {
        .modal-container {
            max-width: 1020px;

            @media(max-width:$size-lg) {
                & > div > div {
                    .modal-header {
                        padding-bottom: $unit-2;
                    }
                }
            }
            @media(max-width:$size-sm) {
                max-width: 90%;
            }
        }
    }
    &.modal-lg {
        & > .modal-container {
            max-width: 90%;
        }
    }
}
.nibnut.student-content {
    .modal.custom-authentication {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $zindex-5;

        & > .modal-overlay {
            background: #d3e9f5; //  url("~@/assets/img/alternating-stars-DARK.png")
        }
        & > .modal-container {
            width: 25vw;
            border: 1px solid $primary-color-light;
            border-radius: 0.25em;
            transition: all 0.3s ease-in;
            box-shadow: 0px 0px 15px 5px $dark-color;

            & > .app-logo {
                position: relative;
                display: inline-block;
                width: 4.5rem;
                left: 50%;
                transform: translate(-50%, 0);
                margin-top: $unit-4;
            }
            & > div > div {
                margin-top: 0;
            }
            @media (max-width: $size-2x) {
                width: 60vw;
            }
            @media (max-width: $size-sm) {
                width: 90%;
            }
        }
        .modal-title.h5, .modal-subtitle {
            font-weight: 500;
            color: $primary-color;
        }
        .modal-title.h5 {
            font-size: 26px;
            @media (max-width: $size-sm) {
                font-size: 21px;
                line-height: 1.2;
            }
        }
        .modal-subtitle {
            font-size: 18px;
        }
        .btn:not(.btn-link) {
            font-weight: 500;
            font-size: 26px;
            @media (max-width: $size-sm) {
                font-size: 21px;
            }
        }
        .form-group {
            .form-label:not(.disabled-field) {
                color: $primary-color;
            }
        }

        &.modal-md {
            .modal-container {
                width: auto;
                @media (max-width: $size-lg) {
                    width: 75vw;
                }
                @media (max-width: $size-sm) {
                    width: 90%;
                }
            }
        }
        &.modal-lg {
            & > .modal-container {
                width: auto;
                @media (max-width: $size-lg) {
                    width: 90%;
                }
            }
        }
    }
}
</style>
